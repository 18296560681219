($ => {

    const $overlay = $('.modal-overlay');

    const $modalToggleHero = $('.modal-toggle-hero');
    const $modalCloseHero = $('.modal-close-hero');

    const $modalToggle = $('.modal-toggle:not(.modal-toggle-hero)');
    const $modalClose = $('.modal-close:not(.modal-close-hero)');

    const toggleDisplay = event => {
        $('.modal:not(.modal-hero)').toggleClass('is-visible');
        event.preventDefault();
    };

    const toggleDisplayHero = event => {
        $('.modal-hero').toggleClass('is-visible');
        event.preventDefault();
    };

    $modalToggle.on('click', toggleDisplay);
    $modalToggleHero.on('click', toggleDisplayHero);

    $modalClose.on('click', event => {
        $('.modal-wrapper:not(.modal-wrapper-hero) iframe').each(function(index) {
            $(this).attr('src', $(this).attr('src'));
            return false;
        });
    });

    $modalCloseHero.on('click', event => {
        $('.modal-wrapper-hero iframe').each(function(index) {
            $(this).attr('src', $(this).attr('src'));
            return false;
        });
        $('.modal-hero').toggleClass('is-visible');
    });

    $overlay.on('click', event => {
        if ($('.modal:not(.modal-hero)').hasClass('is-visible')) {
            $('.modal-wrapper:not(.modal-wrapper-hero) iframe').each(function(index) {
                $(this).attr('src', $(this).attr('src'));
                return false;
            });
            $('.modal:not(.modal-hero)').toggleClass('is-visible');
        }

        if ($('.modal-hero').hasClass('is-visible')) {
            $('.modal-wrapper-hero iframe').each(function(index) {
                $(this).attr('src', $(this).attr('src'));
                return false;
            });
            $('.modal-hero').toggleClass('is-visible');
        }
     });

     const miniModuleToggle = $('.mini-module-modal-toggle');
     const miniModuleToggleTop = $('.mini-module-modal-toggle-top');
     const modalCloser = $('.modal-overlayMm, .modal-closeMm');
     $(miniModuleToggle).click(function(event) {
        $(this).parent().parent().parent().find('.modal').toggleClass('is-visible');
        event.preventDefault();
    });

    $(miniModuleToggleTop).click(function(event) {
        $(this).parent().find('.modal').toggleClass('is-visible');
        event.preventDefault();
    });

    $(modalCloser).click(function(event) {
        $('.modal').removeClass('is-visible');
    });

})(jQuery);
