($ => {

    const $requestAppointment = $('.request-appointment');

    $requestAppointment.find('button').on('click', event => {
        const email = $requestAppointment.find('select[name=email]').val();
        if (email) {
            window.location = 'mailto:' + email + '?subject=Appointment Request';
        } else {
            alert('Please select a location or "Other..."');
        }
        event.preventDefault();
    });

})(jQuery);
