($ => {
    const $registerPatientInitial = $('.register-patient--initial');
    const $registerPatientConfirmed = $('.register-patient--confirmed');

    const $registerPatientAgain = $('.register-patient__again');

    const $registerPatientForm = $('.register-patient-form');
    const registerPatientUrl = $registerPatientForm.attr('action');
    const registerPatientMethod = $registerPatientForm.attr('method');

    const toggleDisplay = (event = null) => {
        if (event) {
            event.preventDefault();
        }
        $registerPatientInitial.toggleClass('hide');
        $registerPatientConfirmed.toggleClass('hide');
    };

    $registerPatientAgain.on('click', toggleDisplay);

    $registerPatientForm.on('submit', event => {
        event.preventDefault();
        $.ajax({
            url: registerPatientUrl,
            type: registerPatientMethod,
            data: $registerPatientForm.serialize(),
            success: () => {
                $registerPatientForm.trigger('reset');
                toggleDisplay();
            },
            error: response => {
                alert(response.responseText);
            },
        });
    });

})(jQuery);
